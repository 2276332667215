import { useTranslations } from 'next-intl';

export const Separator = () => {
  const t = useTranslations('SignUpModal');

  return (
    <div className="flex items-center mt-3 mb-0.5 px-9 w-full">
      <hr className="flex-grow border-t border-gray-300" />
      <span className="text-xs mx-2 text-gray-500">{t('or')}</span>
      <hr className="flex-grow border-t border-gray-300" />
    </div>
  );
};
